<template lang="pug">
section.SectionServices
	.container.py-xxl
		.row
			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-1.svg')"
					heading="Express cargo shipping around Russia and EEU countries"
					text="BQB Express runs over 10 000 directions of delivery in Russia and EEU countries and is experienced in delivery of most categories of cargo. We deliver to Russian regions using our own transport with terms starting from 1 day. Your shipment will be stored in BQB Express regional warehouse network until it is dispatched to the client."
				)
			.w-100.mb-xs.d-md-none

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-2.svg')"
					heading="Courier delivery within the Russian Federation and to international locations"
					text="BQB Express is a mail service operator, license no. 181802 of 16.03.20. We deliver correspondence and parcels to client address, pickup points or parcel terminals in over 100 cities of Russia, as well as international locations. We will pick up the shipment within 4 hours after receiving an application."
				)
			.w-100.mb-xs

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-3.svg')"
					heading="Fulfillment"
					subheading="For internet stores and trade companies"
					text="We pick up cargo from suppliers in any point within Russia or abroad (with further customs processing); deconsolidate the goods and store them in our own warehouses in various Russian regions; we batch orders, draft shipping documents and deliver to Your client."
				)
			.w-100.mb-xs.d-md-none

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-4.svg')"
					heading="Cargo express shipping to international destinations"
					text="We ship consolidated cargo using our own lines from Europe, Asia, the USA and Australia. If necessary, we store shipments at our warehouse in Europe, and then gradually dispatch them to Russia. BQB Express experts perform customs processing “on-the-go”, without unloading cargo at TSAs."
				)
			.w-100.mb-xs

			.col-md-6
				Card(
					:picture="require('../assets/images/bg-service-5.svg')"
					heading="Distributed storage"
					subheading="In Russian cities and abroad"
					text="We distribute and store goods in our own network of regional warehouses. Analyzing Your sales volumes, the BQB system provides recommendations and supports the required amounts of Your goods in specific regions. This way, we reduce transportation costs per unit of goods and save time for Your customers."
				)
</template>

<script>
import Card from './Card.vue'

export default {
	components: {
		Card
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionServices {
	background-color: $c-periwinkle;
	background-image: url(../assets/images/getting.svg);
	background-position: center bottom;
	background-size: calc(100% - 30px);
	background-repeat: no-repeat;
	padding-bottom: 66%;

	@media (min-width: $screen-md) {
		background-size: auto;
		padding-bottom: 0;
		background-position: right calc(50% - 172px) bottom;
	}

	@media (min-width: $screen-lg) {
		background-position: right calc(50% - 224px) bottom;
	}

	@media (min-width: $screen-xl) {
		background-position: right calc(50% - 272px) bottom;
	}
}
</style>
