<template lang="pug">
section.py-xxl
	.container
		.h1.headline.mb-xl Advantages of cooperation with us

		.row
			.col-6.col-lg-5
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-1.svg')"
					heading="Large geography"
					text="Delivery to parcel terminals and pickup points in more than 100 Russian cities"
				)

			.col-6.col-lg-5.offset-lg-1
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-2.svg')"
					heading="Reliability"
					text="Guarantee of VAT confirmation and legal security"
				)
			.w-100.mb-m

			.col-6.col-lg-5
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-3.svg')"
					heading="International cargo shipments"
					text="Cargo pickup at any global location"
				)

			.col-6.col-lg-5.offset-lg-1
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-4.svg')"
					heading="Independence"
					text="Ability to work with different Russian regions without attracting distributors"
				)
			.w-100.mb-m

			.col-6.col-lg-5
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-5.svg')"
					heading="Industry-related solutions"
					text="Cosmetics, technical equipment, household appliances, consumer products"
				)

			.col-6.col-lg-5.offset-lg-1
				IconCardBig(
					:icon="require('../assets/images/icon-advantage-6.svg')"
					heading="Simplicity"
					text="All logistics in a “single-window” mode"
				)
</template>

<script>
import IconCardBig from './IconCardBig.vue'

export default {
	components: {
		IconCardBig
	}
}
</script>
