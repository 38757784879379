<template lang="pug">
section.SectionWork.py-xxl
	.container
		.row.mb-xl
			.col-md-6
				.h1.headline We work with legal entities and private persons
			.w-100.mb-xs.d-md-none

			.col-md-6
				.subline We offer a convenient solution for regular dispatching of goods to Russian regions. All You need is to do is to sell Your products.

		.row
			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-1.svg')"
					text="Mail services provision license No. 181802 of 16.03.20"
				)
			.w-100.mb-m.d-md-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-2.svg')"
					text="Over 10 000 directions of delivery in Russia and EEU countries"
				)
			.w-100.mb-m.d-lg-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-3.svg')"
					text="Urgent cargo pickup and minimum one day delivery"
				)
			.w-100.mb-m.d-md-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-4.svg')"
					text="Cargo storage with order batching and delivery to Your clients"
				)
			.w-100.mb-m.d-lg-none

			.col-md-6.col-lg
				IconCard(
					:icon="require('../assets/images/icon-work-5.svg')"
					text="Experienced in delivery of the majority of product categories"
				)
</template>

<script>
import IconCard from './IconCard.vue'

export default {
	components: {
		IconCard
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionWork {
	background-color: $c-black-squeeze;
}
</style>
