<template lang="pug">
section.SectionRequest
	.row.no-gutters
		.col-lg-6.pt-xl.pb-xxl.SectionRequest-Dark
			.container.container-half.mr-lg-0
				.text-center.text-lg-left
					.h1.SectionRequest-Heading.mb-l Still have questions?#[br]Contact us or leave your contact details and we will call you back

					.row
						.col-md-6
							a.subline.icon.icon-phone.SectionRequest-Contact(href="tel:+78006006600") +7 (800) 600 6 600
						.w-100.mb-s.d-md-none

						.col-md-6
							a.subline.icon.icon-mail.SectionRequest-Contact(href="mailto:bqb@bqb.ru.com") bqb@bqb.ru.com

		.col-lg-6.pt-xl.pb-xxl
			.container.container-half.ml-lg-0
				.SectionRequest-Form.mx-auto
					.row
						.col-md-6.col-lg-12
							.form-group
								label Name
								input.form-control(value="John")

						.w-100.pb-l.d-md-none.d-lg-block

						.col-md-6.col-lg-12
							.form-group
								label Phone
								input.form-control(value="+34 988 23 54 65")

						.w-100.pb-l

					.text-center
						button.btn.btn-app.SectionRequest-Btn Leave an application
</template>

<script>
export default {
	props: {
	},
	data() {
		return {}
	}
}
</script>

<style lang="scss">
@import '../styles/common';

.SectionRequest {
	&-Dark {
		background-color: $c-licorice;
	}

	&-Heading {
		color: $c-white;
	}

	&-Contact {
		color: $c-white;
		border-bottom: none;
		transition: color .14s ease-in-out;

		&::before {
			font-size: 1.2em;
		}
	}

	&-Form {
		@media (min-width: $screen-lg) {
			max-width: 302px;
		}
	}

	&-Btn {
		width: 100%;

		@media (min-width: $screen-md) {
			width: auto;
		}

		@media (min-width: $screen-lg) {
			width: 100%;
		}
	}
}
</style>
